<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="center"
      :style="style_centerblock"
      class="text-center"
    >
      <v-form
        ref="form"
      >
        <v-card-title>
          Please, enter the email key you received by mail
        </v-card-title>
        <v-text-field
          v-model="emailKey"
          :dark="this.$store.state.darkTheme ? true:false"
          label="E-mail key"
          outlined
        />

        <v-btn
          :dark="this.$store.state.darkTheme ? true:false"
          :disabled="!emailKey"
          color="green"
          large
          @click="validate"
        >
          Validate
        </v-btn>

        <v-card-text>
          Reponse : {{ reponse }}
        </v-card-text>
      </v-form>
    </v-row>
  </v-container>
</template>

<script>
  import config from '@/config'
  import axios from 'axios'

  export default {
    data: () => ({
      // Couleur
      red: config.colors.gricad_red_dark1,
      card_bg_dark: config.colors.card_bg_dark,
      card_bg_light: config.colors.card_bg_light,
      barchart_color: config.colors.gricad_blue_dark3,
      blue: config.colors.gricad_oceanblue,
      // Init les v-model
      emailKey: '',
      // Init variable
      reponse: '',
    }),

    computed: {
      style_centerblock () {
        return {
          height: `${this.$store.state.windowH - 200}px`,
          'background-color': 'transparent',
        }
      },
      style_bigbtn () {
        return {
          height: `${this.$store.state.windowH / 4}px`,
          width: `${this.$store.state.windowW / 4}px`,
          'min-width': '400px',
          'background-color': this.blue,
          color: 'white',
        }
      },
    },

    methods: {
      validate () {
        if (this.emailKey === this.$store.state.account.emailKey) {
          axios.get('/username/' + this.$store.state.account.login + '/delete-emailKey')
            .then(response => {
              if (response.data.includes('SUCCESS')) {
                this.reponse = 'You have validated your email !'
                this.emailKey = ''
                this.$store.state.account.emailKey = ''
              }
            })
        } else {
          this.reponse = 'Your emailKey is not the same as in the Ldap. You did not validate your email. Please retry.'
        }
      },
    },

  }
</script>

<style lang="scss">

</style>
